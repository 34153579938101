import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Carousel from "react-slick";
import Card from "components/Card/Card.jsx";
import Video from "components/Video/Video.jsx";

import defaultSectionStyle from "assets/jss/finwe/defaultSectionStyle.jsx";

import image1 from "assets/img/cases/paali/cover.jpg";
import image2 from "assets/img/cases/paali/cad.jpg";
import image3 from "assets/img/cases/paali/installation.jpg";
import image4 from "assets/img/cases/paali/tractor_hw.jpg";
import image5 from "assets/img/cases/paali/trailer_hw.jpg";
import image6 from "assets/img/cases/paali/data_collection.jpg";
import image7 from "assets/img/cases/paali/visualizer.jpg";
import image8 from "assets/img/cases/paali/visualizer2.jpg";

import ESA from "assets/img/logos/esa-480x480.png";
import NLS from "assets/img/logos/nls-480x480.png";
import OSAO from "assets/img/logos/osao-480x480.png";
import Valtra from "assets/img/logos/valtra-480x480.png";
import Junkkari from "assets/img/logos/junkkari-480x480.png";

class PaaliSection extends React.Component {
  render() {
    const { classes } = this.props;
    const carouselSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="SectionContent">

            <div className={classes.title}>
              <h2>Precision Agriculture (ESA NAVISP-EL1-067)</h2>
              <h4>Relative Position of a Tractor-Trailer Ensemble via Optical Sensors</h4>
              <br />
            </div>

            <CustomTabs
              headerColor="info"
              tabs={[
                /*
                {
                  tabName: "Video",
                  tabIcon: undefined,
                  tabContent: (
                    <div>
                      <Video
                        videoSrcURL="https://www.youtube-nocookie.com/embed/E02Bqblce7E"
                        videoTitle="Demo"
                        aspectRatio="56.25%"
                      />
                    </div>
                  )
                },
                */
                {
                  tabName: "Photos",
                  tabIcon: undefined,
                  tabContent: (
                    <div className={classes.section}>
                      <div className={classes.container}>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
                            <Card carousel>
                              <Carousel {...carouselSettings}>
                                <div>
                                  <img src={image1} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image2} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image3} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image4} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image5} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image6} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image7} alt="Slide" className="slick-image" />
                                </div>
                                <div>
                                  <img src={image8} alt="Slide" className="slick-image" />
                                </div>
                              </Carousel>
                            </Card>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  )
                },
                {
                  tabName: "More Info",
                  tabIcon: undefined,
                  tabContent: (
                    <GridContainer>
                      <div className={classes.typo}>
                        <p>
                          Precision agriculture plays a vital role in addressing the global need 
                          for sustainable food production by minimizing resource use and 
                          environmental impact. In collaboration with Finnish Geospatial Research 
                          Institute (FGI), which operates as a research institute within the 
                          National Land Survey of Finland (NLS), we developed innovative
                          optical measurement solutions to determine the <b>precise position of 
                          farming equipment relative to tractors</b>. This approach offers a 
                          cost-efficient alternative to traditional RTK-GNSS systems, which are 
                          often too expensive to be added to each end-equipment. The customer of
                          the project was the European Space Agency (ESA) NAVISP program.
                        </p>
                        <p>
                          Our team designed and tested a comprehensive setup consisting of a 
                          tractor-mounted camera rig and a trailer-mounted marker rig. We evaluated an 
                          array of advanced sensing technologies, including multiple stereo cameras
                          with different base lines and resolutions, a time-of-flight camera, 
                          a LIDAR, and a thermal camera. Different markers, reflectors and machine
                          vision algorithms were compared as means for optical tracking. An IMU was 
                          added to the end-equipment to provide complementary motion data, should the the 
                          optical method occasionally suffer from difficult lighting conditions or dust. 
                          Different designs were iterated using CAD models and 3D printed components. 
                          Several field tests were performed with a car and a trailer before proceeding to 
                          OSAO Muhos field tests with an actual tractor and a seeder ensemble. The final 
                          setup was installed on a tractor and a seeder, and terabytes of data was collected 
                          for further analysis. A reference RTK-GNSS system in the seeder was used to validate
                          the accuracy of the optical measurements.
                        </p>
                        <p>
                          Traditional RTK-GNSS solutions, while accurate, are prohibitively expensive 
                          to implement for each piece of farming equipment. By combining optical 
                          measurements of a few low-cost markers attached to the end-equipment 
                          with precision RTK-GNSS of the tractor and sensor fusion algorithms, we achieved 
                          highly accurate positioning and attitude estimation for end-equipment. The solution
                          also runs in realtime, providing immediate feedback to the driver for example via 
                          a 3D visualization of the whole ensemble. Moreover, multiple working solutions were 
                          found and visible QR style markers are not the only option. For example, a towed 
                          vehicle's existing reflectors or even its thermal signature can be used as a marker. 
                          Our approach provides a scalable alternative by leveraging optical systems to deliver 
                          precise control over different types of end-equipment.
                        </p>
                        <p>
                          We are thankful for ESA for the opportunity to work on this project, for FGI
                          for the excellent collaboration, for OSAO Muhos Agricultural College for 
                          providing us the opportunity to organize multiple field tests using their equipment 
                          and fields, for AGCO Valtra for a visit and insights on tractor technology, 
                          and for Junkkari for the 3D model of the seeder equipment. The project was a great
                          opportunity to combine our skills in optical solutions, sensor fusion, 3D modeling,
                          3D visualization, and prototype development to address a real-world problem.
                        </p>
                        <p>
                          <b>
                            The method developed for agriculture use case can be applied to other use
                            cases where the relative position of two objects needs to be determined.
                            Contact us to learn more about our optical tracking solutions.
                          </b>
                        </p>
                      </div>
                      <div className={classes.typo}>
                        <GridContainer>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto, classes.beforeTitle}>
                            <h4>Customer:</h4>
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={ESA}
                              alt="European Space Agency"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto, classes.beforeTitle}>
                            <h4>Partners:</h4>
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={NLS}
                              alt="National Land Survey of Finland"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          </GridContainer>
                          <GridContainer>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto, classes.beforeTitle}>
                            <h4>In co-operation:</h4>
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={OSAO}
                              alt="OSAO Muhos Agricultural College"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={Valtra}
                              alt="AGCO Valtra"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={3} md={2} className={classes.marginAuto}>
                            <img
                              src={Junkkari}
                              alt="Junkkari"
                              className={classes.imgRounded + " " + classes.imgFluid}
                            />
                          </GridItem>
                        </GridContainer>
                      </div>
                    </GridContainer>
                  )
                },
                {
                  tabName: "Links",
                  tabIcon: undefined,
                  tabContent: (
                    <div>
                      <p className={classes.textCenter}>
                        <a href="https://navisp.esa.int/project/details/237/show">NAVISP-EL1-067 Precision agriculture - high precision coordinate and alignments transfer (ESA)</a>
                      </p>
                      <p className={classes.textCenter}>
                        <a href="https://www.maanmittauslaitos.fi/tutkimus/paali-precision-agriculture-high-precision-coordinate-and-alignments-transfer">PAALI - Precision Agriculture - High Precision Coordinate and Alignments Transfer (by MML)</a>
                      </p>
                      <p className={classes.textCenter}>
                        <a href="https://www.osao.fi/tietoa-hakemisesta/yksikot/muhoksen-yksikko/">OSAO Agricultural College at Muhos (by OSAO)</a>
                      </p>
                    </div>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(defaultSectionStyle)(PaaliSection);
